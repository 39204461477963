exports.components = {
  "component---src-components-page-404-tsx": () => import("./../../../src/components/page/404.tsx" /* webpackChunkName: "component---src-components-page-404-tsx" */),
  "component---src-components-page-500-tsx": () => import("./../../../src/components/page/500.tsx" /* webpackChunkName: "component---src-components-page-500-tsx" */),
  "component---src-components-page-basket-tsx": () => import("./../../../src/components/page/Basket.tsx" /* webpackChunkName: "component---src-components-page-basket-tsx" */),
  "component---src-components-page-bike-comparison-tsx": () => import("./../../../src/components/page/BikeComparison.tsx" /* webpackChunkName: "component---src-components-page-bike-comparison-tsx" */),
  "component---src-components-page-campaign-listing-tsx": () => import("./../../../src/components/page/CampaignListing.tsx" /* webpackChunkName: "component---src-components-page-campaign-listing-tsx" */),
  "component---src-components-page-campaign-tsx": () => import("./../../../src/components/page/Campaign.tsx" /* webpackChunkName: "component---src-components-page-campaign-tsx" */),
  "component---src-components-page-category-tsx": () => import("./../../../src/components/page/Category.tsx" /* webpackChunkName: "component---src-components-page-category-tsx" */),
  "component---src-components-page-checkout-tsx": () => import("./../../../src/components/page/Checkout.tsx" /* webpackChunkName: "component---src-components-page-checkout-tsx" */),
  "component---src-components-page-home-flexible-tsx": () => import("./../../../src/components/page/HomeFlexible.tsx" /* webpackChunkName: "component---src-components-page-home-flexible-tsx" */),
  "component---src-components-page-order-confirm-tsx": () => import("./../../../src/components/page/OrderConfirm.tsx" /* webpackChunkName: "component---src-components-page-order-confirm-tsx" */),
  "component---src-components-page-product-line-tsx": () => import("./../../../src/components/page/ProductLine.tsx" /* webpackChunkName: "component---src-components-page-product-line-tsx" */),
  "component---src-components-page-product-tsx": () => import("./../../../src/components/page/Product.tsx" /* webpackChunkName: "component---src-components-page-product-tsx" */),
  "component---src-components-page-publication-listing-tsx": () => import("./../../../src/components/page/PublicationListing.tsx" /* webpackChunkName: "component---src-components-page-publication-listing-tsx" */),
  "component---src-components-page-publication-tsx": () => import("./../../../src/components/page/Publication.tsx" /* webpackChunkName: "component---src-components-page-publication-tsx" */),
  "component---src-components-page-search-tsx": () => import("./../../../src/components/page/Search.tsx" /* webpackChunkName: "component---src-components-page-search-tsx" */),
  "component---src-components-page-splash-page-simple-tsx": () => import("./../../../src/components/page/SplashPageSimple.tsx" /* webpackChunkName: "component---src-components-page-splash-page-simple-tsx" */),
  "component---src-components-page-store-locator-tsx": () => import("./../../../src/components/page/StoreLocator.tsx" /* webpackChunkName: "component---src-components-page-store-locator-tsx" */)
}

